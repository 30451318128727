import { useState } from 'react';
import { listIcon, addIcon, removeIcon, editIcon } from '../../api/api';
import { uploadFile } from '../../api/req';
import './index.css';
import { InboxOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Upload, Image, Button, message, Input } from 'antd';
import Page from '../../components/page';

const { Dragger } = Upload;

const props = {
  maxCount: 1,
  showUploadList: false,
};

const tableColumns = [
  { title: 'url', dataIndex: 'url', key: 'url' },
  { title: 'name', dataIndex: 'name', key: 'name' },
  { title: 'action', key: 'action' },
];

function IconManager() {
  const [info, setInfo] = useState({ iconList: [], total: 0 });

  if (!info.iconList.length) {
    listIcon().then((res) => {
      let item = { ...info };
      item.iconList = res.data.list.map((i) => {
        return { ...i, key: i.id };
      });
      item.total = res.data.total;
      setInfo(item);
    });
  }

  let uploadOnDrop = (e, id) => {
    console.log('uploadOnDrop ' + id);
    console.log(e);
    const file = e;
    const isImg = file.type.startsWith('image');
    if (!isImg) {
      message.error(`${file.name} is not a img file`);
      return;
    }
    uploadFile('/web/icon/upload', file).then((res) => {
      let freshURL = res.data.url;
      if (!id) {
        let name = file.name.substring(0, file.name.lastIndexOf('.'));
        addIcon(freshURL, name).then((res) => {
          let item = res.data;
          item.key = item.id;
          let iconList = info.iconList ? info.iconList : [];
          iconList = [item].concat(iconList);
          info.iconList = iconList;
          info.total = info.total + 1;
          setInfo({ ...info });
        });
      } else {
        editIcon(id, freshURL).then((res) => {
          let iconList = info.iconList.map((i) => {
            if (i.id == id) {
              i.icon = freshURL;
            }
            return { ...i };
          });
          setInfo({ ...info, iconList });
        });
      }
    });
  };

  let remove = (id) => {
    removeIcon(id).then((res) => {
      if (res.succeed && !res.data) {
        let item = { ...info };
        item.iconList = info.iconList.filter((i) => i.id != id);
        item.total = item.total - 1;
        setInfo(item);
      }
    });
  };

  let setNameEditing = (id) => {
    let item = { ...info };
    item.iconList = info.iconList.map((i) => {
      return { ...i, editingName: i.id == id };
    });
    setInfo(item);
  };

  let changeName = (id, name) => {
    let item = { ...info };
    let needCallAPI = false;
    item.iconList = info.iconList.map((i) => {
      if (i.id == id) {
        if (i.name != name) {
          needCallAPI = true;
        }
        i.name = name;
      }
      return { ...i, editingName: false };
    });
    setInfo(item);
    if (needCallAPI) {
      editIcon(id, '', name);
    }
  };

  tableColumns.map((item) => {
    if (item.key == 'url') {
      item.render = (res, record) => {
        return (
          <Dragger
            {...props}
            beforeUpload={(e) => {
              uploadOnDrop(e, record.id);
              return false;
            }}
          >
            <Image preview={false} width={50} src={res} />
          </Dragger>
        );
      };
    }
    if (item.key == 'name') {
      item.render = (res, record) => {
        let value = res;
        if (record.editingName) {
          return (
            <div class='name'>
              <Input
                defaultValue={res}
                onPressEnter={() => {
                  changeName(record.id, value);
                }}
                onChange={(e) => {
                  value = e.target.value;
                  console.log(e);
                }}
                onBlur={() => {
                  changeName(record.id, value);
                }}
              />
            </div>
          );
        } else {
          return (
            <div
              class='name'
              onClick={() => {
                setNameEditing(record.id);
              }}
            >
              {res}
              {<EditOutlined />}
            </div>
          );
        }
      };
    }
    if (item.key == 'action') {
      item.render = (res, record) => {
        return (
          <div>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                remove(record.id);
              }}
            ></Button>
          </div>
        );
      };
    }
    return item;
  });

  return Page(
    <div class='icon-manager-container'>
      <div class='uploader'>
        <Dragger
          {...props}
          beforeUpload={(e) => {
            uploadOnDrop(e, '');
            return false;
          }}
        >
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>
            Click or drag file to this area to upload
          </p>
          <p className='ant-upload-hint'>
            Support for a single or bulk upload. Strictly prohibited from
            uploading company data or other banned files.
          </p>
        </Dragger>
      </div>
      <div class='icon-list'>
        <Table
          columns={tableColumns}
          dataSource={info.iconList ? info.iconList : []}
          pagination={{ pageSize: 6, total: info.total }}
          bordered
          scroll={{ y: '80vh', x: '30vw' }}
        />
      </div>
    </div>
  );
}

export default IconManager;

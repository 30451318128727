import { useState } from 'react';
import { uploadFile } from '../../api/req';
import { InboxOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Upload, Image, Button, message } from 'antd';
import { ErrorAlert } from '../../components/alert';
import Page from '../../components/page';
import './index.css';

const { Dragger } = Upload;

const props = {
  maxCount: 1,
  showUploadList: false,
};

function Uploader() {
  const [url, setURL] = useState('');
  const [err, setErr] = useState(null);

  let copyURL = () => {
    const clipboardObj = navigator.clipboard;
    if (!clipboardObj) {
      message.error('不支持剪切板');
      return;
    }
    clipboardObj
      .writeText(url)
      .then(() => {
        message.info('复制成功');
      })
      .catch((err) => {
        message.info('复制失败' + err);
      });
  };

  let uploadOnDrop = (e, id) => {
    console.log('uploadOnDrop ' + id);
    console.log(e);
    const file = e;
    // const isImg = file.type.startsWith('image');
    // if (!isImg) {
    //   message.error(`${file.name} is not a img file`);
    //   return;
    // }
    uploadFile('/api/utils/upload', file)
      .then((res) => {
        let freshURL = res.data.url;
        setURL(freshURL);
      })
      .catch((err) => {
        message.error(`upload failed ${err}`);
      });
  };

  let image = url ? (
    <div class='image-content'>
      <Image src={url} />
      <div class='url-container'>{url}</div>
      <Button title='copy url' onClick={copyURL}>
        copy url
      </Button>
      {ErrorAlert(err?.show, err?.title, err?.message)}
    </div>
  ) : (
    <div />
  );

  return Page(
    <div>
      {image}
      <div class='uploader'>
        <Dragger
          {...props}
          beforeUpload={(e) => {
            uploadOnDrop(e, '');
            return false;
          }}
        >
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>
            Click or drag file to this area to upload
          </p>
          <p className='ant-upload-hint'>
            Support for a single or bulk upload. Strictly prohibited from
            uploading company data or other banned files.
          </p>
        </Dragger>
      </div>
    </div>
  );
}

export default Uploader;

import PageTop from './pageTop';
import PageBottom from './pageBottom';
import './page.css';

function Page(content) {
  return (
    <div className='page'>
      <PageTop />
      <div className='page-content'>{content}</div>
      <PageBottom />
    </div>
  );
}

export default Page;

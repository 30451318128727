import './App.css';
import { Link } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Link className='link' to='/login'> Login </Link>
      <Link className='link' to='/icon'> icon </Link>
    </div>
  );
}

export default App;
import './pageTop.css';
import { Link } from 'react-router-dom';

function PageTop(info) {
  return (
    <div class='top-container'>
      <Link className='link' to='/icon'>
        {' '}
        icon{' '}
      </Link>
      <Link className='link' to='/upload'>
        {' '}
        upload{' '}
      </Link>
    </div>
  );
}

export default PageTop;

const env = 'prod';
const envInfoMap = {
  dev: {
    host: 'http://localhost:8090',
  },
  prod: {
    host: 'https://api.payrecord.karsa.info',
  },
};
const envInfo = envInfoMap[env];

export function req(url, para) {
  let token = localStorage.getItem('token');
  let headers = {};
  if (token) {
    headers = { token: 'token ' + token };
  }
  return fetch(envInfo.host + url, {
    method: 'POST',
    headers,
    body: JSON.stringify(para),
    smode: 'cors',
  })
    .then((res) => {
      let token = res.headers.get('token');
      if (token) {
        localStorage.setItem('token', token);
      }
      return res;
    })
    .then((res) => {
      return res.json();
    });
}

export function uploadFile(url, file) {
  let token = localStorage.getItem('token');
  let headers = {};
  if (token) {
    headers = { token: 'token ' + token };
  }
  var data = new FormData();
  data.append('file', file);
  return fetch(envInfo.host + url, {
    method: 'POST',
    headers,
    body: data,
    smode: 'cors',
  }).then((res) => res.json());
}

import { Button, Input} from 'antd';
import Password from 'antd/es/input/Password';
import './index.css';
import { useState } from 'react';
import { ErrorAlert } from '../../components/alert';
import { useNavigate } from "react-router-dom";
import {login as loginAPI} from '../../api/api';
const md5 = require('js-md5');


function LoginPage() {  
  const navigate = useNavigate();
  const [info, setInfo] = useState({loginPara:{}, err:{}});

  function login() {       
    loginAPI(info.loginPara.user, md5(info.loginPara.pwd)).then(res => {      
      console.log(res)        
      if (res.succeed && !res.data) {
        navigate('/icon')
      }  
    }).catch(err => {
      setInfo({err: {show: !info?.err?.show, title:'登录错误', message:''}}) 
      setTimeout(() => {
        setInfo({err: {show: !info?.err?.show}}) 
      }, 1000);
    })
    // setInfo({err: {show: !info?.err?.show, title:'登录错误', message:''}}) 
    // console.log(info)
    // setTimeout(() => {
    //   navigate('/tab1');  
    // }, 1000);
  
  }

  return (
    <div>
      {ErrorAlert(info.err?.show,info.err?.title,info.err?.message)}
      <div className='login-container'>        
        <Input placeholder='账号' onChange={(res) => {
          info.loginPara.user = res.target.value;      
        }} />
        <Password placeholder='密码' onChange={(res) => {
          info.loginPara.pwd = res.target.value;
        }} />
        <Button onClick={login}>登录</Button>
      </div>
    </div>  
  )
  
}

export default LoginPage;
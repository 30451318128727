import {req} from './req';

export function login(user, pwd) {
  return req("/web/user/login", {user, pwd})
}

export function listIcon() {
  return req("/web/icon/list")
}

export function editIcon(id, url, name) {
  return req('/web/icon/edit', {id,icon:url, name})
}

export function removeIcon(id) {
  return req('/web/icon/remove', {id})
}

export function addIcon(url, name) {
  return req('/web/icon/add', {icon:url, name})
}


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import App from './App';
import LoginPage from './pages/login';
import IconManager from './pages/iconmanager/index';
import Uploader from './pages/Uploader/index';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/icon' element={<IconManager />} />
        <Route path='/upload' element={<Uploader />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
